<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-lg-10">
                                <form class="form-inline">
                                    <div class="form-group mb-2">
                                        <input @input="getConsignment()" class="form-control" id="inputPassword2" placeholder="Search by MR..." type="search" v-model="filter.search">
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered table-centered mb-0" v-if="consignments.length>0">
                                <thead>
                                <tr>
                                    <th>Consignment No</th>
                                    <th>Truck No</th>
                                    <th>MR No</th>
                                    <th>Batch No</th>
                                    <th>Quality</th>
                                    <th>Production Line</th>
                                    <th>No of Bales</th>
                                    <th>Weight (kg)</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-bind:key="index" v-for="(key, index) in batches">
                                    <td :rowspan="key.rowspan_count" v-if="key.consignment">
                                        <a class="text-body font-weight-bold" href="javascript:void(0)">{{key.consignment.name}}</a>
                                    </td>
                                    <td :rowspan="key.rowspan_count" v-if="key.consignment && key.consignment.attributes.length>0">{{key.consignment.attributes[0][2].fact.value}}</td>
                                    <td :rowspan="key.rowspan_count" v-if="key.consignment && key.consignment.attributes.length>1">{{key.consignment.attributes[1][2].fact.value}}</td>
                                    <td>{{key.name}}</td>
                                    <td v-if="key.attributes.length>0">{{key.attributes[0][2].fact.value}}</td>
                                    <td v-if="key.attributes.length>1">{{key.attributes[1][2].fact.value}}</td>
                                    <td v-if="key.attributes.length>2">{{key.attributes[2][2].fact.value}}</td>
                                    <td v-if="key.attributes.length>3">{{key.attributes[3][2].fact.value}}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center mt-4 mb-3" v-else-if="!removeLoader">
                                <div class="spinner-border avatar-md text-primary" role="status"></div>
                            </div>
                            <template v-else>
                                <h4 class="card-title text-muted text-center mt-lg-4">No records found.</h4>
                                <h5 class="card-title text-muted text-center mb-lg-4">Change search criteria and try
                                    again</h5>
                            </template>
                        </div>
                        <!-- end table-responsive -->

                    </div>
                </div>
            </div> <!-- end col -->
        </div>

        <!-- Large modal -->
        <div aria-hidden="true" aria-labelledby="myLargeModalLabel" class="modal fade" id="bs-example-modal-lg"
             role="dialog"
             tabindex="-1">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="myLargeModalLabel">Associated Batches</h4>
                        <button aria-hidden="true" class="close" data-dismiss="modal" type="button">×</button>
                    </div>
                    <div class="modal-body mb-lg-3">
                        <div class="table-responsive">
                            <table class="table table-sm table-bordered table-centered mb-0 mt-3">
                                <thead>
                                <tr>
                                    <th>Batch No.</th>
                                    <th>Status</th>
                                    <th>Quality of Yarn</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-bind:key="index" v-for="(key, index) in associated_batches">
                                    <td><a class="text-body font-weight-bold" href="javascript:void(0)">{{key.name}}</a></td>
                                    <td>
                                        <h5 v-if="key.status === 'InProgress'"><span class="badge badge-warning-lighten"><i class="mdi mdi-coin"></i> In Progress</span></h5>
                                        <h5 v-if="key.status === 'Completed'"><span class="badge badge-success-lighten"><i class="mdi mdi-coin"></i> Completed</span></h5>
                                    </td>
                                    <td v-if="key.attributes.length>0">{{key.attributes[0][2].fact.value}}</td>
                                    <td v-else>NA</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import moment from "moment";
    import _ from "lodash";

    export default {
        name: "Reports",
        props: ['batchid'],
        computed: {
            ...mapGetters(["currentUser"])
        },
        data() {
            return {
                filter: {
                    search: null
                },
                consignments: [],
                batches: [],
                pid: null,
                consignment_processes: [],
                mr_processes: [],
                associated_batches: [],
                clicked_process_name: null,
                removeLoader: false,
                removeConsignmentLoader: false,
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await this.getConsignment();
            },
            async getConsignment() {
                this.removeLoader = false;
                this.batches = []
                try {
                    if(this.filter.search){
                        let {data} = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
                            params: {
                                process_type: 'Consignment',
                                page: 0,
                                per_page: 100,
                                attribute_name: 'MR Number',
                                attribute_value: this.filter.search,
                                attribute_value_exact: false,
                                fetch: {
                                    attributes: [
                                        {
                                            attribute_name: "Truck No.",
                                            attribute_step: 0
                                        },
                                        {
                                            attribute_name: "MR Number",
                                            attribute_step: 4
                                        },
                                    ]
                                }
                            }
                        });
                        this.consignments = data.processes;
                    }else {
                        let {data} = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
                            params: {
                                process_type: 'Consignment',
                                page: 0,
                                per_page: 100,
                                fetch: {
                                    attributes: [
                                        {
                                            attribute_name: "Truck No.",
                                            attribute_step: 0
                                        },
                                        {
                                            attribute_name: "MR Number",
                                            attribute_step: 4
                                        },
                                    ]
                                }
                            }
                        });
                        this.consignments = data.processes;
                    }
                    for (let i = 0; i < this.consignments.length; i++) {
                        let p = this.consignments[i];
                        if (p.attributes.length > 1 && p.attributes[1][2].fact.value) {
                            let reply = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
                                params: {
                                    process_type: 'Batch',
                                    page: 0,
                                    per_page: 100,
                                    attribute_name: 'MR Number',
                                    attribute_value: p.attributes[1][2].fact.value.toString(),
                                    attribute_value_exact: true,
                                    fetch: {
                                        attributes: [
                                            {
                                                attribute_name: "Jute Quality",
                                                attribute_step: 0
                                            },
                                            {
                                                attribute_name: "Batch Type",
                                                attribute_step: 0
                                            },
                                            {
                                                attribute_name: "Quantity",
                                                attribute_step: 0
                                            },
                                            {
                                                attribute_name: "Weight (kg)",
                                                attribute_step: 0
                                            }
                                        ]
                                    },
                                }
                            });
                            this.batches = _.union(this.batches, reply.data.processes.map((b, index) => {
                                if (index === 0) {
                                    return {
                                        ...b,
                                        consignment: p,
                                        rowspan_count: reply.data.processes.length
                                    }
                                } else {
                                    return {
                                        ...b
                                    }
                                }
                            }));
                        }
                    }
                } catch (e) {

                } finally {
                    this.removeLoader = true;
                }
            },
        }
    }
</script>

<style scoped>
    .selected-row {
        background-color: #f1f3fa;
    }
</style>